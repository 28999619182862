<template>
    <b-overlay :show="showLoader">
        <b-card class="" :title="$t('menu.subcategory.move_supply')">
            <CustomTable  ref="moveSuppliesTable" :fields="computedColumnDefs" api-url="/api/client/v1/warehouses/move_supply">
                <template #buttons>
                    <div>
                        <b-button v-if="warehouses.length > 1" variant="primary" @click="startMovingSupply">
                            {{ $t('material.move_supplies.start_move_supply') }}
                        </b-button>
                        <div v-else>
                            <b-alert show variant="info" class="p-1">
                                {{ $t("material.move_supplies.one_warehouse") }}
                            </b-alert>
                        </div>
                    </div>
                </template>
                <template #state="{item}">
                    <b-badge variant="warning" v-if="item.editing_in_progress && item.confirmed">
                        {{ $t('general.yes') }}
                    </b-badge>
                    <b-badge variant="success" v-else-if="item.confirmed">
                        {{ $t('general.no') }}
                    </b-badge>
                    <b-badge variant="danger" v-else>
                        {{ $t('general.not_included_in_stock') }}
                    </b-badge>
                </template>
                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item)">
                            {{ $t('general.delete') }}
                        </b-button>
                        <b-button class="mr-1 m-0 button d-flex justify-content-center align-items-center" :variant="item.confirmed?'warning':'success'"  size="sm" style="width: 60px;" @click="editRow(item)">
                            <span v-if="item.confirmed"> {{ $t('general.edit') }}</span>
                            <span v-else> {{ $t('general.continue') }}</span>
                        </b-button>
                        <b-button class="m-0 button" variant="secondary"  size="sm" @click="viewMoveSupplyPrintout(item)" :disabled="!item.confirmed">
                            <span style="white-space: nowrap;">
                                {{ $t('general.view_printout') }}
                            </span>
                        </b-button>
                    </div>
                </template>
            </CustomTable>
            <report-preview-modal ref="moveSupplyReportModal" :file-name="fileName" :api-url="apiUrl"/>

        </b-card>
    </b-overlay>
</template>

<script>
    import {BButton, BBadge, BCard, BOverlay, BAlert } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: { CustomTable, ReportPreviewModal, BBadge, BCard, BOverlay, BButton, BAlert },
        computed:{
            computedColumnDefs() {
                return [
                    {
                        key: 'move_supply_code',
                        label: this.$t('table_fields.code'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'move_supply_code.w',
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.cash_register_created_at'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDateOnly,
                        filterType: 'date',
                        minWidth: 75
                    },
                    {
                        key: 'from',
                        label: this.$t('table_fields.from_warehouse'),
                        filter: true,
                        filterType: 'select',
                        filterParamsName: 'name',
                        filterParams: this.warehouses,
                        reduce: ele => ele.id,
                        formatter: this.getWarehouseName,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        minWidth: 150
                    },
                    {
                        key: 'to',
                        label: this.$t('table_fields.to_warehouse'),
                        filter: true,
                        filterType: 'select',
                        filterParamsName: 'name',
                        filterParams: this.warehouses,
                        reduce: ele => ele.id,
                        formatter: this.getWarehouseName,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        minWidth: 150
                    },
                    {
                        key: 'ingredients',
                        label: this.$t('table_fields.num_of_moved'),
                        filter: false,
                        filterType: 'input',
                        formatter: this.getNumOfMoved,
                        filterFormatter: this.formatFilterPaymentType,
                        permissions: true,
                        maxWidth: 300
                    },
                    {
                        key: 'state',
                        label:  this.$t('table_fields.changes'),
                        permissions: true,
                        minWidth: 160
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]
            }
        },
        data() {
            return {
                warehouses: [],
                moveSupply: [],
                showLoader: false,
                fileName: '',
                apiUrl: ''
            }
        },
        methods: {
            async viewMoveSupplyPrintout(data) {
                this.apiUrl = `/api/reports/v1/printout/move_supply/${  data.id  }`
                this.fileName =  `PremikMedSkladisci_${data.move_supply_code}.pdf`
                await this.$refs.moveSupplyReportModal.show(this.fileName, this.apiUrl)
            },
            getNumOfMoved(value) {
                if (value) {
                    return value.length
                }
                return '0'
            },
            getWarehouseName(value) {
                if (this.warehouses.find(ele => ele.id === value)) {
                    return this.warehouses.find(ele => ele.id === value).name
                }
                return '/'
            },
            async loadData() {
                try {
                    this.showLoader = true
                
                    const response1 = this.$http.get('/api/client/v1/warehouses/move_supply')
                    const response2 = this.$http.get('/api/client/v1/warehouses/')
                    
                    const responses = await Promise.all([response1, response2])
                    this.moveSupply = responses[0].data ?? []
                    this.warehouses = responses[1].data ?? []

                    const indexWarehousesFrom = this.computedColumnDefs.findIndex(ele => ele.key === 'from')
                    const indexWarehousesTo = this.computedColumnDefs.findIndex(ele => ele.key === 'to')

                    if (indexWarehousesFrom >= 0) {
                        this.computedColumnDefs[indexWarehousesFrom].filterParams = this.warehouses
                    }

                    if (indexWarehousesTo >= 0) {
                        this.computedColumnDefs[indexWarehousesTo].filterParams = this.warehouses
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async editRow(data) {
                try {
                    await this.$http.post(`/api/client/v1/move_supplies_temporary/create/${data.id}`)

                    await this.$router.push({name: 'edit_move_supply', params:{id: data.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async remove(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/warehouses/move_supply/${data.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    if (this.$refs.moveSuppliesTable) {
                        await this.$refs.moveSuppliesTable.reloadData()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async startMovingSupply() {
                try {
                    const response = await this.$http.post('/api/client/v1/move_supplies_temporary/create/000000000000000000000000')
                    const moveSupplyTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_move_supply', params:{id: moveSupplyTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
